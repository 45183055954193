// @ts-nocheck
import { memo, useEffect, useRef, useState } from 'react';
import styles from './acceptOrder.module.scss'
// import { ReactComponent as ReturntoSearchIcon } from '../../assets/images/ReturntoSearch.svg';
// import {ReactComponent as QuestionIcon} from '../../assets/images/question.svg'; 
import { ReactComponent as ScrollDownImage } from '../../assets/images/scroll-down-image.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warning-seller-icon.svg';
import { routes, purchaseOrder, referenceDataKeys, userRole } from '../../../common';
import { useLocation, useNavigate } from 'react-router';
import { Dialog } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import clsx from 'clsx';
import moment from 'moment';
import { useGlobalStore, useSellerOrderStore, getSocketConnection, getChannelWindow } from '@bryzos/giss-ui-library';
import 'moment-timezone';
import { format4DigitAmount, formatToTwoDecimalPlaces } from '../../helper';
import ReminderYouAreAlmostTherePopup from './ReminderYouAreAlmostTherePopup/ReminderYouAreAlmostTherePopup';
import useSaveSellerViewedOrder from '../../hooks/useSaveSellerViewedOrder';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import OrderConfirmationSeller from './orderConfirmation';
import PdfMakePage from '../PdfMake/pdfMake';
import { descriptionLines, getOtherDescriptionLines } from '../../utility/pdfUtils';

const SubmitApplicationDialog = (props) => {

    const [hasAcceptedTnC, setHasAcceptedTnC] = useState(false);

    const tncChangeHandler = ($event) => {
        $event.stopPropagation();
        setHasAcceptedTnC(state => !state);
    }

    return (
        <Dialog
            open={props.open ?? false}
            onClose={props.onClose}
            transitionDuration={200}
            hideBackdrop
            classes={{
                root: styles.SubmitApp,
                paper: styles.dialogContent,
            }}
        >
            <div className={styles.disclaimer}>
                <span className={styles.leftIcon}><WarningIcon /></span>

                IMPORTANT
                <span className={styles.rightIcon}><WarningIcon /></span>
            </div>
            <ul>
                <li>All delivered material must be new, prime, within the stated tolerance per the product specification and include mill test reports.</li>
                <li>Material must be packaged & loaded for forklift or magnetic offloading.</li>
                <li>Strip load only, no pyramid loading for pipe.</li>
                <li>Material must be reasonably free of oxidation and pitting.</li>
                <li>Maximum bundle weight is 5,000 pounds.</li>
                <li>By “Accepting Order,” you agree to fulfill this order (including delivery, fuel surcharges and other fulfillment-related items) at the published price.  The only<br /> acceptable variance between the published price and your future invoices will be reasonable quantity reconciliations (ie. ordered vs shipped).</li>
            </ul>

            <label className='containerChk'>
                <input type='checkbox' checked={hasAcceptedTnC} onChange={($event) => { tncChangeHandler($event) }} />
                <span className='checkmark'></span>
                <span className='lblChk'>
                    I understand and agree.
                </span>
            </label>
            <div className={styles.flx}>
                <button onClick={props.onClose} className={styles.cancelBtn}>Cancel</button>
                <button className={styles.submitBtn} onClick={($event) => { props.acceptOrder($event);}} disabled={!hasAcceptedTnC}>Accept Order</button>
            </div>
        </Dialog>
    );
};

const AcceptOrder = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const channelWindow =  getChannelWindow();
    const { showPopup, isEditMode, isDispute, index } = location.state;
    const [showScrollDownToSee, setShowScrollDownToSee] = useState(true);
    const { userData, originalLoggedInUserData , referenceData} = useGlobalStore();
    const stateRef = referenceData?.ref_states;
    const [poIndex, setPoIndex] = useState(index);
    const filteredPoList = useSellerOrderStore(state => state.filteredPoList);
    const setPOCart = useSellerOrderStore(state => state.setPOCart);
    const ordersCart = useSellerOrderStore(state => state.ordersCart);
    const changePoToBeAccepted = useSellerOrderStore(state => state.changePoToBeAccepted);
    const navigatePageTo = useSellerOrderStore(state => state.navigatePageTo);
    const setNavigatePageTo = useSellerOrderStore(state => state.setNavigatePageTo);
    const orderDetail = useSellerOrderStore(state => state.orderToBeShownInOrderAccept);
    const setOrderDetail = useSellerOrderStore(state => state.setOrderToBeShownInOrderAccept);
    const [errorMessage, setErrorMessage] = useState('');
    const [openErrorPopUp, setOpenErrorPopUp] = useState(false);
    const errorPopupDetail = useSellerOrderStore(state => state.errorPopupDetail);
    const setErrorPopupDetail = useSellerOrderStore(state => state.setErrorPopupDetail);
    const [isReminderPopup, setIsReminderPopup] = useState(true);
    const sellerSettingsData = useGlobalStore(state => state.sellerSettingsData);
    const sellerCompanyName = useGlobalStore(state => state.sellerCompanyName);
    const [openReminderYouAreAlmostTherePopup, setOpenReminderYouAreAlmostTherePopup] = useState(sellerSettingsData ? false : true);
    const [openSubmitApp, setOpenSubmitApp] = useState(showPopup && sellerSettingsData);
    const [openOrderConfirmation, setOpenOrderConfirmation] = useState(false);
    const [disableOnclick, setDisableOnclick] = useState(false);

    const saveSellerViewedOrder = useSaveSellerViewedOrder();

    useEffect(() => {
        if (sellerSettingsData) {
            setOpenReminderYouAreAlmostTherePopup(false);
        } else {
            setDisableOnclick(true)
            setOpenReminderYouAreAlmostTherePopup(true);
        }
    }, [sellerSettingsData]);

    useEffect(()=>{
        if(!isEditMode)
        setPoIndex(filteredPoList.findIndex( order => order.buyer_po_number === orderDetail.buyer_po_number));
    },[filteredPoList]);

    
    const backNavigation = useGlobalStore(state => state.backNavigation);
    const setBackNavigation = useGlobalStore(state => state.setBackNavigation);

    useEffect(() => {
        const order = filteredPoList[poIndex] ? filteredPoList[poIndex] : orderDetail;
        order.items?.sort((a, b) => {
            return a.po_line - b.po_line;
        });
        if (!order.is_order_view) {
            saveSellerViewedOrder.mutateAsync({ data: { id: order.id } }).then(async () => {
                if(ordersCart){
                    ordersCart[index].is_order_view = true;
                    setPOCart([...ordersCart]);
                }
            }).catch(e => {
                console.log(e)
            });
        }
        setOrderDetail(order);
    }, [poIndex, filteredPoList]);

    useEffect(() => {
        if (navigatePageTo) {
            changePoToBeAccepted(null);
            setNavigatePageTo('');
            setOpenOrderConfirmation(true)
        }
    }, [navigatePageTo]);

    useEffect(() => {
        if (errorPopupDetail) {
            setOpenSubmitApp(false);
            setErrorMessage(errorPopupDetail);
            setBackNavigation(-3)
            setOpenErrorPopUp(true);
        }
    }, [errorPopupDetail]);

    const state = stateRef?.find(stateDetail => stateDetail.id == orderDetail?.state_id)?.code;
    const deliveryDate = moment.utc(orderDetail?.delivery_date).tz('America/Chicago').format('MMM DD, YYYY');
    const availableAfter = referenceData?.ref_general_settings.find(setting => setting.name === referenceDataKeys.sellerAvailInMinKey).value;
    const domesticMaterialText = referenceData?.ref_general_settings.find(setting => setting.name === referenceDataKeys.domesticMaterialTextKey).value;
    const createdDate = orderDetail.payment_method === purchaseOrder.paymentMethodBNPL ? orderDetail.created_date : orderDetail.ach_po_approved_date;
    const availableTime = moment.utc(createdDate).add((+availableAfter) + 1, 'minute').local().format('h:mm a');
    const materialValue = +orderDetail.seller_po_price;
    const salesTaxValue = +orderDetail.seller_sales_tax;
    const totalOrderValue = (materialValue + salesTaxValue).toFixed(2);
    const startIndex = 0;
    const lastIndex = filteredPoList.length - 1;
    const productsContainerRef = useRef();
    orderDetail.createdDate = createdDate;
    orderDetail.userType = userRole.sellerUser;
    orderDetail.deliveryDate = moment.utc(orderDetail?.delivery_date).tz('America/Chicago').format('M/D/YY');
    orderDetail.createDateFormat = moment.utc(createdDate).tz('America/Chicago').format('M/D/YY');
    orderDetail.stateCode = state;
    orderDetail.totalOrderValue = totalOrderValue;
    orderDetail.sellerCompanyName = sellerCompanyName;

    const handleScroll = () => {
        if(productsContainerRef.current.scrollTop !== 0)
        setShowScrollDownToSee(false);
    };

    const handleClickOpen = ($event) => {
        $event.stopPropagation();
        if (sellerSettingsData) {
            setOpenReminderYouAreAlmostTherePopup(false);
            setOpenSubmitApp(true);
        } else {
            setIsReminderPopup(false);
            setOpenReminderYouAreAlmostTherePopup(true);
        }
    };

    const handleErrorClose = ($event) => {
        $event.stopPropagation();
        setOpenErrorPopUp(false);
        setErrorPopupDetail(null);
        navigate(routes.orderPage);
    };
    const handleSubmitClose = ($event) => {
        $event.stopPropagation();
        setOpenSubmitApp(false);
    };
    const previousPage = () => {
        if (poIndex > startIndex) {
            scrollToTopOfOrdersList();
            setShowScrollDownToSee(true);
            setPoIndex((state) => {
                const index = state - 1;
                const order = filteredPoList[index] ? filteredPoList[index] : orderDetail;
                markOrderAsViewd(order, index);
                return index;
            });
            
        }
    }
    const nextPage = () => {
        if (poIndex < lastIndex) {
            scrollToTopOfOrdersList();
            setShowScrollDownToSee(true);
            setPoIndex((state) => {
                const index = state + 1;
                const order = filteredPoList[index] ? filteredPoList[index] : orderDetail;
                markOrderAsViewd(order, index);
                return index;
            });
        }
    }

    const markOrderAsViewd = (order: any, i: number) => {
        if (!order) {
            return;
        }
        if (!order.is_order_view) {
            saveSellerViewedOrder.mutateAsync({ data: { id: order.id } }).then(() => {
                ordersCart[i].is_order_view = true;
                setPOCart([...ordersCart]);
            }).catch(e => {
                console.log(e)
            });
        }
    }

    const acceptOrder = ($event) => {
        const socket = getSocketConnection();
        console.log("Accepting Order..." + orderDetail.buyer_po_number);
        const payload = { 
            buyer_po_number: orderDetail.buyer_po_number, 
            super_admin_id: originalLoggedInUserData?.id ? String(originalLoggedInUserData.id) : undefined
        }
        socket.emit('acceptPo', payload);
        $event.stopPropagation();
        setOpenSubmitApp(false);
        changePoToBeAccepted(orderDetail.buyer_po_number);
        // navigate(routes.orderConfirmationPageSeller);
    }

    const ErrorDialog = memo((props) => {
        return (
            <Dialog
                open={props.open}
                onClose={props.onClose}
                transitionDuration={200}
                hideBackdrop
                classes={{
                    root: styles.ErrorDialog,
                    paper: styles.dialogContent
                }}

            >
                {/* <p>{props.errorMessage}</p> */}
                <span className={styles.closeIcon} onClick={props.onClose}><CloseIcon /></span>
                <p className={styles.youJustMissetext} >YOU JUST MISSED IT!</p>
                <p className={styles.thisOrderMissedtest}>This order was <i>just </i> claimed <br />
                    by another Seller.</p>
                <p className={styles.missedtest}>Continue to review more orders & click “Accept Order” as soon as you know you can fulfill the order. Good luck!</p>
                {/* <button className={styles.submitBtn} onClick={($event) => props.onClose($event)}>Ok</button> */}
                <button className={styles.claimAnotherOrderbtn} onClick={($event) => props.onClose($event)}>Claim Another Order</button>
            </Dialog>
        )
    })

    function display(data) {
        const lines = data.split('\n');
        const firstLine = lines[0];
        const restLines = lines.slice(1);

        return (
            <div>
                <p className="liHead">{firstLine}</p>
                {restLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        );
    }

    const productListing = orderDetail?.items?.map((product, index) => {
        return (<tr key={index}>
            <td>{index + 1}</td>
            <td >
                <span className={styles.description}>
                    {display(product.description)}
                </span>
                {/* <div className={styles.stripLoad}>
                    Strip Load / Include MTRs
                </div> */}
                {(product.domestic_material_only > 0) && <div className={styles.domesticMaterial}>
                    {domesticMaterialText}
                </div>}
            </td>
            <td>
                {formatToTwoDecimalPlaces(product.qty)}
                <div className={styles.textCenter}>{product.qty_unit}</div>
            </td>
            <td>
                {product.price_unit === "Lb" ? format4DigitAmount(product.seller_price_per_unit) : formatToTwoDecimalPlaces(product.seller_price_per_unit)}
                <div className={styles.textCenter}>{product.price_unit}</div>
            </td>
            <td><div className={styles.div1}>$</div><div className={styles.div2}>{formatToTwoDecimalPlaces(product.seller_line_total)}</div></td>
        </tr>);
    })

    const disablePreviousButton = startIndex === poIndex;
    const disableNextButton = lastIndex === poIndex;

    const scrollToBottomOfOrdersList = () => {
        setShowScrollDownToSee(false);
        productsContainerRef.current.scrollTop = productsContainerRef.current.scrollHeight;
    }
    const scrollToTopOfOrdersList = () => {
        productsContainerRef.current.scrollTop = 0;
    }

    const handleExportPDfClick = ($event) => {
        $event.stopPropagation();
        if (sellerSettingsData) {
            setOpenReminderYouAreAlmostTherePopup(false);
            setDisableOnclick(false)
        } else {
            setIsReminderPopup(false);
            setDisableOnclick(true);
            setOpenReminderYouAreAlmostTherePopup(true);
        }
    }

    const calculateSellerLineWeight = (data) => {
        return formatToTwoDecimalPlaces(data.total_weight)
    }
    
    const getCartItems = () =>{
        const {items} = orderDetail;
        const formattedItems = items.map((item, index) => ({
            description: descriptionLines(item.description), 
            otherDescription: getOtherDescriptionLines(item.description),
            product_tag: item.product_tag,                  
            domesticMaterialOnly: item.domestic_material_only ? '\nDomestic (USA) Material Only' : '',
            qty: formatToTwoDecimalPlaces(item.qty),                                 
            qty_unit: item.qty_unit,                       
            price_unit: item.price_unit,      
            price: item.price_unit === 'Lb' ? format4DigitAmount(item.seller_price_per_unit) : formatToTwoDecimalPlaces(item.seller_price_per_unit) ,  
            line_weight:calculateSellerLineWeight(item),
            extended: formatToTwoDecimalPlaces(item.seller_line_total),
            line_weight_unit: "Lb",    
            line_no: index,
            po_line: index.toString(),
            total_weight:item.total_weight
        }));
        return formattedItems
    } 

    return (
        <>
        {!openOrderConfirmation ? 
            <div>
                {/* <div className={clsx(styles.BuyerSetting, 'bgImg')}>
                    <div className='headerPanel commonHeader'>
                        <Header />
                    </div> */}
                    
                    {isEditMode &&
                        <div className={styles.disputeHeader}>
                            PO# {orderDetail.po_number}
                        </div>
                    }
                    <div className={clsx(styles.acceptOrderContent,'bgBlurContent')}>
                        <ErrorDialog
                            open={openErrorPopUp}
                            onClose={$event => handleErrorClose($event)}
                            errorMessage={errorMessage} />
                        {!isEditMode && <div className={styles.acceptOrderHead} >
                            { disablePreviousButton ? <>
                                <button className={clsx(styles.btnPreNextPo, disablePreviousButton && styles.nextprevdisabledBtn )} disabled={disablePreviousButton} onClick={previousPage}><span>Prev <br />PO</span></button>
                            </> :
                            <>
                            <CommonTooltip
                            title={"Click here to jump back to a previous order from the Order Summary screen"}
                            tooltiplabel={<button className={clsx(styles.btnPreNextPo, disablePreviousButton && styles.nextprevdisabledBtn )} disabled={disablePreviousButton} onClick={previousPage}><span>Prev <br />PO</span></button>}
                            placement={'right-start'}
                            classes={{
                                popper: 'tooltipPopper',
                                tooltip: 'tooltipMain2 tooltipMain5',
                                arrow: 'tooltipLeftArrow1'
                        }}
                            localStorageKey="preBtnOfOrdersTooltip"
                        />
                        </>
                            }
                        
                            {orderDetail.claimed_by === purchaseOrder.pending ?
                                <CommonTooltip
                                    title={"Orders will sit in Preview Mode for 60 minutes before being available to accept. This will give you the opportunity to review this order prior to accepting it"}
                                    tooltiplabel={
                                        <button className={styles.orderPreviewBtn}>
                                            <span className={styles.leftIcon}><WarningIcon /></span>
                                            <span className={styles.rightIcon}><WarningIcon /></span>
                                            <span>Order Preview</span>
                                            <span className={styles.acceptReview}>Available to Accept at {availableTime}</span>
                                        </button>
                                    }
                                    placement={'bottom'}
                                    classes={{
                                        popper: 'tooltipPopper',
                                        tooltip: 'tooltipMain2 tooltipMain7',
                                        arrow: 'tooltipLeftArrow1'
                                    }}
                                    localStorageKey="previeBtnOfOrdersTooltip"
                                />
                                    
                                :
                                <button className={styles.acceptOrderBtn} onClick={(event) => handleClickOpen(event)}>
                                    <SubmitApplicationDialog open={openSubmitApp} onClose={$event => handleSubmitClose($event)} acceptOrder={acceptOrder} />
                                    <span>ACCEPT ORDER</span>
                                </button>
                            }
                            {disableNextButton ? <>
                                <button className={clsx(styles.btnPreNextPo, disableNextButton && styles.nextprevdisabledBtn )} onClick={nextPage} disabled={disableNextButton}><span>Next PO</span></button>
                            </> : <>
                            <CommonTooltip
                                title={"Click here to jump to the next order from the Order Summary screen"}
                                tooltiplabel={<button className={clsx(styles.btnPreNextPo, disableNextButton && styles.nextprevdisabledBtn )} onClick={nextPage} disabled={disableNextButton}><span>Next PO</span></button>}
                                placement={'left-start'}
                                classes={{
                                    popper: 'tooltipPopper',
                                    tooltip: 'tooltipMain2 tooltipMain4',
                                    arrow: 'tooltipLeftArrow1'
                            }}
                                localStorageKey="nextBtnOfOrdersTooltip"
                            />
                            </>}
                        
                        </div>
                        }
                        <div className={styles.acceptOrderInformation} >
                            <div className={styles.acceptOrderInformationCol1}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Total Order Value:</td>
                                            <td>$ {formatToTwoDecimalPlaces(totalOrderValue)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Weight:</td>
                                            <td>{formatToTwoDecimalPlaces(orderDetail.total_weight)} LBS</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={styles.acceptOrderInformationCol2}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Due Date:</td>
                                            <td>{deliveryDate}</td>
                                        </tr>
                                        <tr>
                                            <td>Freight Term:</td>
                                            <td>{orderDetail.freight_term} (FOB Destination)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={styles.acceptOrderInformationCol3}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Delivery Destination:</td>
                                        <td>{orderDetail.city}, {state} {orderDetail.zip}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.addPoLineTable}>
                            <table>
                                <thead>
                                    <tr>
                                        <th><span>LN</span></th>
                                        <th><span>DESCRIPTION</span></th>
                                        <th><span>QTY</span></th>
                                        <th><span>$/UM</span></th>
                                        <th colSpan={2}><span>EXT</span></th>
                                    </tr>
                                </thead>
                                <tbody ref={productsContainerRef} onScroll={handleScroll}>
                                    {productListing}
                                </tbody>
                            </table>
                            {orderDetail?.items?.length > 2 && showScrollDownToSee && (
                                <div className={styles.scrollDownImage}>
                                    <span>
                                        <ScrollDownImage onClick={scrollToBottomOfOrdersList} />
                                    </span>
                                </div>
                            )}
                        </div>
                           <div className={clsx(styles.totalAmt, 'acceptOrderExportBtn', (!channelWindow?.fetchPdf || !channelWindow?.generatePdf) && styles.exportNotAvailable)}>
                           {(channelWindow?.fetchPdf || channelWindow?.generatePdf) && <div onClick={handleExportPDfClick}><PdfMakePage sellerData={orderDetail} disableOnclick={disableOnclick} getCartItems={getCartItems} /></div>}
                            <table>
                                <tbody>
                                   
                                    <tr>
                                        <td><span className={styles.saleTax}>Material Total</span></td>
                                        <td><span className={styles.saleTax}>$</span></td>
                                        <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(orderDetail.seller_po_price)}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className={styles.saleTax}>Sales Tax</span></td>
                                        <td><span className={styles.saleTax}>$</span></td>
                                        <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(orderDetail.seller_sales_tax)}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className={styles.totalPurchase}>Total Purchase</span></td>
                                        <td><span className={styles.totalPurchase}>$</span></td>
                                        <td><span className={styles.totalPurchase}>{formatToTwoDecimalPlaces(totalOrderValue)}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        
                       
                        <div className={styles.acceptOrderBottom}>
                            <div className={styles.returnToSearch}><button onClick={() => navigate(backNavigation)}>Back</button></div>
                            <div className={styles.textOfCondition}>After clicking “Accept Order,” the next screen will be your order confirmation.
                                Additionally, we will send you a purchase order for your records.</div>
                            {/* <div className={styles.returnToSearch}><button onClick={() => navigate(backNavigation)}>Back</button></div> */}
                        </div>
                    </div>
                {/* </div> */}
                <ReminderYouAreAlmostTherePopup open={openReminderYouAreAlmostTherePopup} close = {() => setOpenReminderYouAreAlmostTherePopup(false)} isReminderPopup= {isReminderPopup}/>
            </div>
            :
            <OrderConfirmationSeller poNumber={orderDetail.buyer_po_number} />
        }
        </>
    )
}
export default AcceptOrder;