// @ts-nocheck
import { reactQueryKeys, routes } from '../../common';
import { useEventListener } from '@mantine/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { OTPInputComponent } from '../component/OTPInput';
import { emojiRemoverRegex, useGlobalStore, getChannelWindow,useAuthStore } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import useOnSubmitLogin from '../hooks/useOnSubmitLogin';
import { ReactComponent as ShowPassIcon } from '../assets/images/show-pass.svg';
import { ReactComponent as HidePassIcon } from '../assets/images/hide-pass.svg';

const Login = () => {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const signupUser = useGlobalStore(state => state.signupUser);
    const setIsManualLogin = useGlobalStore(state => state.setIsManualLogin);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const { register, watch, handleSubmit, setError, setValue, clearErrors, trigger, formState: { errors, isValid, submitCount } } = useForm({
        resolver: yupResolver(
            yup.object({
                email: yup.string().trim().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
                    message: 'Please enter valid email'
                }).required('Email is required'),
                password: yup.string().trim()
            }).required()
        ),
        mode: 'onSubmit',
    });
    const {loginError, setLoginError, submitLogin} = useAuthStore()
    const onSubmitLogin =  useOnSubmitLogin(setLoginError);
    const password = watch('password');

    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const handleFocus = () => {
        setShowPasswordInput(true);
    };

    const handleEmailFocus = () => {
        setShowPasswordInput(false);
    };

    useEffect(() => {
        if (errors.root?.serverError)
            clearErrors('root.serverError')
    }, [watch('email'), watch('password')])

    useEffect(()=>{
        if(signupUser){
            submitLogin(signupUser)
        }
    },[signupUser])

    useEffect(()=>{
        if(loginError){
            setError("root.serverError",{message: loginError})
        }
    },[loginError])

    const keydownRef = useEventListener('keydown', function (event) {
        if (event.key === 'Enter') {
            handleOnClickLogin()
        }
    }
    );
    const handleOnClickLogin = () => {
        setIsManualLogin(true)
        handleSubmit(submitLogin)()
    } 

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };
    
    return (
        <div>
        <table ref={keydownRef}>
            <tbody>
                <tr className='inputBody'>
                    <td className='enterEmail'>
                        <input type="email" name="email" autoFocus onFocus={handleEmailFocus} {...register("email")}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                setLoginError();
                                register("email").onChange(e);
                            }}
                            placeholder='Enter Email' />
                        <p className='errorText'>{errors.email?.message}</p>
                        {(isValid && (password?.length > 1) && !errors.root?.serverError?.message && !loginError) ?
                            <button className={`pressBtn`} onClick={handleOnClickLogin} type="button">Press ENTER to start session</button>
                            :
                            <p className='errorText errorFont'>{errors.root?.serverError?.message}</p>
                        }
                    </td>
                    <td className='enterEmail'>
                    <span className='togglePassWrapper'>
                        <div>
                            <input type={passwordVisibility ? 'password' : 'text'} {...register("password")} placeholder='Enter Password'
                                onChange={(e) => {
                                    setLoginError();
                                    register("password").onChange(e);
                                }} />
                        </div>
                        <button className='showPassBtn' onClick={togglePasswordVisibility}>
                            {passwordVisibility ? <ShowPassIcon /> : <HidePassIcon />}
                        </button>
                    </span>
                        {/* <p className='errorText'>{errors.password?.message}</p> */}
                    </td>
                </tr>
            </tbody>
        </table>
        {(channelWindow?.getLoginCredential) && 
            <div className='forgot-password'>
                <button onClick={()=>navigate(routes.forgotPassword)} className='forgot-password-btn'>Forgot Password?</button>
            </div>
        }
        </div>
    );
};

export default Login;