// @ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Dialog, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styles from "./ReminderYouAreAlmostTherePopup.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/tnc-close.svg";
import clsx from "clsx";
import InputField from "../../../component/InputField/InputField";
import { useEffect, useState } from "react";
import { CustomMenu } from "../../buyer/CustomMenu";
import * as yup from "yup";
import {
  SEND_INVOICES_TO,
  SHIPPING_DOCS_TO,
  formatPhoneNumber,
  formatPhoneNumberRemovingCountryCode,
} from "../../../helper";
import useSaveSellerSetting from "../../../hooks/useSaveSellerSetting";
import Loader from "../../../Loader/Loader";
import { useGlobalStore } from "@bryzos/giss-ui-library";
import { useImmer } from "use-immer";
import { ReactComponent as ErrorMessageIcon } from "../../../assets/images/error-warning.svg";
import axios from "axios";
import useGetCompanyLists from "src/renderer/hooks/useGetCompanyLists";

const schema = yup.object({
  companyName: yup.string().required("Company Name is not valid"),
  yourCompany: yup.string().trim().required('Your Company is not valid'),
  companyAddressLine: yup.string().required("Company Address is not valid"),
  companyCity: yup.string().required("Company Address is not valid"),
  companyState: yup.number().required("Company Address is not valid"),
  companyZipCode: yup.string().required("Company Address is not valid").min(5,'Company Address is not valid'),
  firstName: yup.string().required("Your Name is not valid"),
  lastName: yup.string().required("Your Name is not valid"),
  emailAddress: yup
    .string()
    .required("Email/Phone is not valid")
    .email("Email/Phone is not valid"),
  phoneNo: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Email/Phone is not valid")
    .required("Phone number is required"),
});

const ReminderYouAreAlmostTherePopup = ({ isReminderPopup, open, close }) => {
  const { userData, setSellerSettingsData , referenceData} = useGlobalStore();

  const [states, setStates] = useState([]);
  const [showSucessPopup, setShowSucessPopup] = useState(false);
  const [errorKeys, setErrorKeys] = useImmer([]);
  const [showLoader, setShowLoader] = useState(true);
  const [companyNameValue, setCompanyNameValue] = useImmer(null);
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [isCompanyNameExists, setIsCompanyNameExists] = useState(false);
  const [yourCompanyValue, setYourCompanyValue] = useImmer(null);
  const [yourCompanyInput, setYourCompanyInput] = useState("");
  const [disableYourCompany, setDisableYourCompany] = useState(true);
  const [yourCompanyList, setYourCompanyList] = useState([]);

  const { data: companyListsData, isLoading: isCompanyListsDataLoading, } = useGetCompanyLists(open);

  const {
    mutate: saveSellerSetting,
    isLoading: isSellerSettingLoading,
    data: saveSellerSettingData,
  } = useSaveSellerSetting(userData?.data?.id);

  const {
    handleSubmit,
    control,
    setFocus,
    watch,
    setValue,
    getValues,
    reset,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const singleErrorKey = errorKeys.find((x) => errors[x]);
  const phoneNo = watch("phoneNo");

  useEffect(() => {
    if(open)
    axios.get(import.meta.env.VITE_API_SERVICE + '/user/sellingPreference')
    .then(response => {
      const getSellerSettingData = response.data.data;

      setValue("companyName", getSellerSettingData.company_name)
      setValue("yourCompany", getSellerSettingData.client_company)
      setYourCompanyInput(getSellerSettingData.client_company ?? "")
      setDisableYourCompany(getSellerSettingData.company_name === null)
      setIsCompanyNameExists(getSellerSettingData.company_name);
      setValue('companyAddressLine', getSellerSettingData.company_address_line1);
      setValue('companyCity', getSellerSettingData.company_address_city);
      setValue('companyState', getSellerSettingData.company_address_state_id);
      setValue('companyZipCode', getSellerSettingData.company_address_zip);
      setValue('firstName', getSellerSettingData.first_name);
      setValue('lastName', getSellerSettingData.last_name);
      setValue('emailAddress', getSellerSettingData.email_id);
      setValue('phoneNo', formatPhoneNumberRemovingCountryCode(getSellerSettingData.phone));
      setShowLoader(false);
      if (
        getSellerSettingData?.company_name &&
        getSellerSettingData?.client_company &&
        getSellerSettingData?.company_address_line1 && 
        getSellerSettingData?.company_address_city && 
        getSellerSettingData?.company_address_state_id && 
        getSellerSettingData?.company_address_zip && 
        getSellerSettingData?.first_name && 
        getSellerSettingData?.last_name && 
        getSellerSettingData?.email_id && 
        getSellerSettingData?.phone 
        ) {
        setSellerSettingsData(true);
      }
    })
  }, [open]);

  useEffect(() => {
    if (saveSellerSettingData && !isSellerSettingLoading) {
      setShowSucessPopup(true);
      setShowLoader(false);
    }
  }, [isSellerSettingLoading, saveSellerSettingData]);

  const handlePhoneNoChange = (event) => {
    const { value } = event.target;
    setValue("phoneNo", formatPhoneNumber(value));
  };

  useEffect(() => {
    if (referenceData?.ref_states?.length) {
      setStates(
        referenceData.ref_states.map((state) => ({
          title: state.code,
          value: state.id,
        }))
      );
    }
  }, [referenceData]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    handleStateZipValidation('companyZipCode', 'companyState')
}, [watch('companyZipCode'), watch('companyState')])

  useEffect(() => {
    if(watch("companyName") !== null && Array.isArray(companyListsData)){
        const companyData = companyListsData?.find((companyData) => companyData.company_name === watch("companyName"))
        setYourCompanyList(companyData?.client_company ?? [])
    }
},[watch("companyName")])

  const sellerSettingFormHandler = (data) => {
    setErrorKeys([]);
    setShowLoader(true);
    saveSellerSetting({
      data: {
        company_name: data.companyName,
        client_company: data.yourCompany,
        address: {
          line1: data.companyAddressLine,
          city: data.companyCity,
          state_id: data.companyState,
          zip: data.companyZipCode,
        },
        first_name: data.firstName,
        last_name: data.lastName,
        email_id: data.emailAddress,
        phone: data.phoneNo,
        send_invoices_to: SEND_INVOICES_TO,
        shipping_docs_to: SHIPPING_DOCS_TO,
      },
    });
  };

  const sucessPopupCloseHandler = () => {
    if (saveSellerSettingData) {
      setSellerSettingsData(true);
    }
    close();
  };

  const showErrorKeyVal = (fieldNames) => {
    setErrorKeys(fieldNames);
  };


  const handleStateZipValidation = async (zipCode, stateCode) => {
    if (getValues(zipCode)?.length > 4 && getValues(stateCode)) {
      const payload = {
        data: {
          state_id: getValues(stateCode),
          zip_code: parseInt(getValues(zipCode)),
        },
      };
      const checkStateZipResponse = await axios.post(
        import.meta.env.VITE_API_SERVICE + "/user/checkStateZip",
        payload
      );
      if (checkStateZipResponse.data.data === true) {
        clearErrors([stateCode, zipCode]);
      } else {
        setError(
          stateCode,
          { message: "The zip code and state code do not match" },
          { shouldFocus: true }
        );
        setError(
          zipCode,
          { message: "The zip code and state code do not match" },
          { shouldFocus: true }
        );
      }
    }
  };

  const handleFormSubmit = () => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(sellerSettingFormHandler)();
    } else {
      return;
    }
  };

  return (
    <Dialog
      open={open}
      transitionDuration={200}
      hideBackdrop
      classes={{
        root: showSucessPopup ? styles.successPopup : styles.reminderPopup,
        paper: styles.dialogContent,
      }}
    >
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {showSucessPopup ? (
            <div className={styles.successPopupMain}>
              <span
                className={styles.closeIcon}
                onClick={sucessPopupCloseHandler}
              >
                <CloseIcon />
              </span>
              <p className={styles.successText}>Success</p>
              <button
                className={styles.btnsuccess}
                onClick={sucessPopupCloseHandler}
              >
                Ok
              </button>
            </div>
          ) : (
              <>
                <span
                  className={styles.closeIcon}
                  onClick={sucessPopupCloseHandler}
                >
                  <CloseIcon />
                </span>

              {isReminderPopup ? (
                <div className={styles.reminderPopupTitle}>
                  <p className={styles.titleText}>
                    REMINDER: TELL US WHO YOU ARE
                  </p>
                  <p className={styles.titleSmallText}>
                    We need to know a little bit about you before you can begin
                    accepting orders. Please provide the following information.{" "}
                  </p>
                </div>
              ) : (
                <div className={styles.reminderPopupTitle}>
                  <p className={styles.titleText}>YOU'RE ALMOST THERE!</p>
                  <p className={styles.titleSmallText}>
                    We need a few more details before we can confirm this order
                    with you.
                  </p>
                </div>
              )}

              <div className={styles.dFlex}>
                {singleErrorKey && errors[singleErrorKey]?.message && (
                  <span className={styles.errorMessage}>
                    <ErrorMessageIcon /> {errors[singleErrorKey]?.message}
                  </span>
                )}
              </div>

              <div
                className={clsx(
                  styles.FormInputGroup,
                  errors.companyName && styles.FormInputGroupError
                )}
                onClick={() => showErrorKeyVal(["companyName"])}
              >
                <span className={styles.lblInput}>Company Name</span>
                <span
                  className={clsx(
                    styles.inputSection, styles.comanyName,
                    errors.companyName && styles.borderOfError
                  )}
                >
                  {isCompanyNameExists ?
                    <p className={styles.comanyNameInput1}>{watch("companyName")}</p>
                    :
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field: { ...rest } }) => (
                        <Autocomplete
                            value={companyNameValue}
                            onChange={(event, value) => {
                                setCompanyNameValue(value);
                                if(value?.company_name){
                                  setDisableYourCompany(false)
                              }else{
                                  setDisableYourCompany(true)
                                  setYourCompanyList([])
                                  setYourCompanyInput("")
                              }
                                rest.onChange(value?.company_name ?? null);
                            }}
                            inputValue={companyNameInput}
                            onInputChange={(event, newInputValue) => {
                                setCompanyNameInput(newInputValue);
                                setValue("companyName", getValues("companyName"), { shouldDirty: true });
                            }}
                            className={'companySelectDropdown'}
                            id="controllable-states-demo"
                            classes={{
                                root: styles.autoCompleteDesc,
                                popper: styles.autocompleteDescPanel,
                                paper: styles.autocompleteDescInnerPanel,
                                listbox: styles.listAutoComletePanel,
                              }}
                            options={companyListsData?.length ? companyListsData : []}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} placeholder="Select company name" />}
                            getOptionLabel={(item) => {
                                return item?.company_name ?? "";
                            }}
                        />
                      )}
                    />
                  }
                </span>
              </div>

              <div className={clsx(styles.FormInputGroup, errors.yourCompany && styles.FormInputGroupError)}
                  onClick={() => showErrorKeyVal(['yourCompany'])}>
                  <span className={styles.lblInput}>
                      Your Company
                  </span>
                  <span className={clsx(styles.inputSection, styles.comanyName, errors.yourCompany && styles.borderOfError)}>
                  <Controller
                      name="yourCompany"
                      control={control}
                      render={({ field: { ...rest } }) => (
                          <Autocomplete
                              freeSolo
                              disabled={disableYourCompany}
                              value={yourCompanyValue}
                              onChange={(event, value) => {
                                  setYourCompanyValue(value);
                                  rest.onChange(value ?? null);
                              }}
                              inputValue={yourCompanyInput}
                              onInputChange={(event, newInputValue) => {
                                  setYourCompanyInput(newInputValue);
                                  rest.onChange(newInputValue)
                              }}
                              className={'companySelectDropdown'}
                              id="controllable-states-demo"
                              classes={{
                                  root: styles.autoCompleteDesc,
                                  popper: styles.autocompleteDescPanel,
                                  paper: styles.autocompleteDescInnerPanel,
                                  listbox: styles.listAutoComletePanel,
                                  }}
                              options={yourCompanyList?.length ? yourCompanyList : []}
                              sx={{ width: '100%' }}
                              renderInput={(params) => <TextField {...params} placeholder="Select company name" />}
                              getOptionLabel={(item) => {
                                  return item ?? "";
                              }}
                          />
                          )}
                      />
                  </span>
              </div>


              <div
                className={clsx(
                  styles.FormInputGroup,
                  (errors.companyAddressLine ||
                    errors.companyState ||
                    errors.companyCity ||
                    errors.companyZipCode) &&
                    styles.FormInputGroupError
                )}
                onClick={() =>
                  showErrorKeyVal([
                    "companyAddressLine",
                    "companyState",
                    "companyCity",
                    "companyZipCode",
                  ])
                }
              >
                <span className={clsx(styles.lblInput, styles.lblAdress)}>
                  Company Address
                </span>
                <div className="dflex flexColunm">
                  <span
                    className={clsx(
                      styles.inputSection,
                      styles.bdrBtmRightRadius0,
                      styles.bdrBtm0,
                      errors.companyAddressLine && styles.borderOfError
                    )}
                  >
                    <InputField
                      fieldName="companyAddressLine"
                      placeholder="Address"
                      control={control}
                    />
                  </span>
                  <div className="dflex">
                    <span
                      className={clsx(
                        styles.inputSection,
                        styles.cityInput,
                        styles.bdrRadius0,
                        styles.bdrRight0,
                        errors.companyCity && styles.borderOfError
                      )}
                    >
                      <InputField
                        fieldName="companyCity"
                        placeholder="City"
                        control={control}
                      />
                    </span>
                    <span
                      className={clsx(
                        styles.inputSection,
                        styles.companyState,
                        styles.bdrRadius0,
                        styles.bdrRight0,
                        errors.companyState && styles.borderOfError
                      )}
                    >
                      <CustomMenu
                        control={control}
                        name={"companyState"}
                        placeholder={"State"}
                        MenuProps={{
                          classes: {
                            paper: styles.Dropdownpaper,
                            list: styles.muiMenuList,
                          },
                        }}
                        items={states}
                        className={"selectDropdown"}
                      />
                      {/* <InputField
                        fieldName="companyState"
                        placeholder="State"
                        control={control}
                      /> */}
                    </span>
                    <span
                      className={clsx(
                        styles.inputSection,
                        styles.zipCodeInput,
                        styles.bdrTopRightRadius0,
                        errors.companyZipCode && styles.borderOfError
                      )}
                    >
                      <input
                        {...register("companyZipCode")}
                        onChange={(e) => {
                          register("companyZipCode").onChange(e);
                            const zipCode = e.target.value.replace(/\D/g, '');
                            setValue('companyZipCode', zipCode);
                          }}
                          placeholder="Zip Code"
                          control={control}
                          type="tel"
                          maxLength="5"
                        />
                        {/* <InputField
                      fieldName="companyZipCode"
                      placeholder="Zip Code"
                      control={control}
                    /> */}
                      </span>
                    </div>
                  </div>
                </div>

              <div
                className={clsx(
                  styles.FormInputGroup,
                  (errors.firstName || errors.lastName) &&
                    styles.FormInputGroupError
                )}
                onClick={() => showErrorKeyVal(["firstName", "lastName"])}
              >
                <span className={styles.lblInput}>Your Name</span>
                <span
                  className={clsx(
                    styles.inputSection,
                    styles.bdrRadius0,
                    styles.bdrRight0,
                    errors.firstName && styles.borderOfError
                  )}
                >
                  <InputField
                    fieldName="firstName"
                    placeholder="First name"
                    control={control}
                  />
                </span>
                <span
                  className={clsx(
                    styles.inputSection,
                    errors.lastName && styles.borderOfError
                  )}
                >
                  <InputField
                    fieldName="lastName"
                    placeholder="Last name"
                    control={control}
                  />
                </span>
              </div>

              <div
                className={clsx(
                  styles.FormInputGroup,
                  (errors.emailAddress || errors.phoneNo) &&
                    styles.FormInputGroupError
                )}
                onClick={() => showErrorKeyVal(["emailAddress", "phoneNo"])}
              >
                <span className={styles.lblInput}>Email / Phone</span>
                <span
                  className={clsx(
                    styles.inputSection,
                    styles.bdrRadius0,
                    styles.bdrRight0,
                    errors.emailAddress && styles.borderOfError
                  )}
                >
                  <InputField
                    fieldName="emailAddress"
                    placeholder="Email address"
                    control={control}
                  />
                </span>
                <span
                  className={clsx(
                    styles.inputSection,
                    styles.phoneNo,
                    errors.phoneNo && styles.borderOfError
                  )}
                >
                  <input
                    maxLength="14"
                    type="tel"
                    {...register("phoneNo")}
                    onChange={(e) => {
                      register("phoneNo").onChange(e);
                      handlePhoneNoChange(e);
                    }}
                    placeholder="(xxx) xxx-xxxx"
                  />
                </span>
              </div>

              <div className={styles.noteText}>
                <p>
                  Please note you will also need to upload your W-9 and complete
                  the banking info to receive payment on this order.
                </p>
              </div>

                <div className={styles.settingBtnMain}>
                  <button onClick={handleFormSubmit} type="submit" className={styles.saveSettingsBtn}>
                    Save Settings
                  </button>
                </div>
              </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ReminderYouAreAlmostTherePopup;
