import {  useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "src/common";
import { ProductPricingModel } from "src/renderer/types/Search";
import { commomKeys, emojiRemoverRegex, useGlobalStore, validateEmailWithSuffix } from '@bryzos/giss-ui-library';
import DialogPopup from "src/renderer/component/DialogPopup/DialogPopup";

type ShareProductPricingOrAppState = {
    selectedProducts: ProductPricingModel[],
    sessionId: string,
    shareHandler: (emailTo: string, emailContent: string) => Promise<void>,
    handleCloseWidget: () => void,
    isShareWidget: boolean
};

const ShareProductPricingOrApp: React.FC<ShareProductPricingOrAppState> = ({selectedProducts,  handleCloseWidget, shareHandler, isShareWidget}) => {
    const { userData } = useGlobalStore();
    const [emailData, setEmailData] = useState('');
    const [emailError, setEmailError] = useState('');
    const [textarea, setTextarea] = useState('');
    const [isSendInProgress, setIsSendInProgress]= useState(false);
    const [shareProductsLength, setShareProductsLength] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        const singleProduct = userData.singleProduct;
        if(singleProduct){
            if (singleProduct.length === 0) {
                setShareProductsLength(selectedProducts.length === 1 ? `${selectedProducts.length} Price` : `${selectedProducts.length} Prices`)
            }else{
                setShareProductsLength(singleProduct.length === 1 ? `${singleProduct.length} Price` : `${singleProduct.length} Prices` )
            }
        }
    },[userData.singleProduct, selectedProducts])

    
    useEffect(()=>{
        if(isShareWidget){
            setEmailData('');
            setTextarea('');
            setEmailError('');
        }
    },[isShareWidget])

    const handleValidation = (emailTo: string) => {
        setEmailData(emailTo);
        if(emailTo.length !== 0){
            setEmailError(validateEmailWithSuffix(emailTo));
        }
    }
    
    const handleSubmitData = async() => {
        if (emailData.trim() === '') {
            setEmailError('Please enter email address')

        } else if (emailData.trim() !== '' && emailError === '') {
            
            setIsSendInProgress(true);
            try{
                if(emailData.trim().toLowerCase() === userData.data.email_id.toLowerCase()){
                    setEmailError('To and from email cannot be same.');
                    setIsSendInProgress(false);
                }else{
                    const data = await shareHandler(emailData, textarea.trim())
                    if (
                        typeof data === "object" &&
                        "error_message" in data
                    ) {
                        setErrorMessage(data?.error_message);
                        setIsSendInProgress(false);
                    }else{
                        setTextarea('')
                        setEmailData('')
                        navigate(routes.successPage)
                    }
                }
            }catch(err){
                setIsSendInProgress(false);
                console.error(err);
            }
        }
    }

    const onCancel = () => {
        setTextarea('')
        setEmailData('')
        handleCloseWidget();
    }

    return <div className='widgetBody'>
        <div>
            <div className='widgetHead'>
                {!isShareWidget ? 'Share selected prices with a friend or coworker:' : 'Share this desktop widget with a friend or coworker:'}

            </div>
            <div className='widgetEmailInput'>
                <input className={`email-id-share `} type="email" value={emailData} onChange={event => {
                    event.target.value = event.target.value.replace(emojiRemoverRegex, '');
                    setEmailData(event.target.value)}}
                    onBlur={(event:React.FocusEvent<HTMLInputElement>) => handleValidation(event.target.value)} placeholder='yourfriend@thatcompany.com'></input>
            </div>
            {emailError.length !== 0 &&
                <p className='errorText1'>{emailError}</p>

            }
            <div className='widgetTextInput'>
                <textarea className='smsTextShare  ' name='share-content' value={textarea} onChange={(event) => { 
                    event.target.value = event.target.value.replace(emojiRemoverRegex, '');
                    setTextarea(event.target.value)
                     }}
                    placeholder={!isShareWidget ? `The ${shareProductsLength} you have selected will be shared with your friend. Write your friend a note so they know it is not spam email.` :'Write your friend a note so they know it is not a spam email.'}>
                </textarea>
            </div>
            <div className='widgetButtons'>
                <button onClick={onCancel} className='cancelBtn'>Cancel</button>
                <button className='sendBtn' disabled={isSendInProgress} onClick={handleSubmitData}>Send</button>
            </div>
        </div>
        <DialogPopup
            dialogContent={errorMessage}
            dialogBtnTitle={commomKeys.errorBtnTitle}
            type={commomKeys.actionStatus.error}
            open={!!errorMessage}
            onClose={() => setErrorMessage('')}
        />

    </div>
}

export default ShareProductPricingOrApp;