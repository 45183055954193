import { getUnitPostScript } from "@bryzos/giss-ui-library";
import { formatToTwoDecimalPlaces } from "../helper";

export const descriptionLines = (description) => {
    const descriptionArray = description.split('\n');
    const firstDescriptionLine = descriptionArray[0];
    return firstDescriptionLine;
}

export const getOtherDescriptionLines = (description) => {
    const descriptionArray = description.split('\n');
    const otherDescriptionLines = descriptionArray.slice(1).join(" "); 
    return otherDescriptionLines;
};

export const calculateSellerTotalOrderWeight = (items) => {
    let totalOrderWeights = 0;
    items?.forEach((item) => {
        totalOrderWeights += +item.total_weight;  
    });
    return formatToTwoDecimalPlaces(totalOrderWeights); 
};

export const calculateBuyerTotalOrderWeight = (dataArray) => {
    let totalOrderWeights = 0;
    dataArray?.forEach((data) => {
        let lineWeight = 0;

        if (data?.descriptionObj?.buyerPricing_LB) {
            const updatedUnit = getUnitPostScript(data.qty_unit);
            const actualBuyingPricePerUnit = parseFloat(data.descriptionObj[`buyerPricing_${updatedUnit}`].replace(/[\$,]/g, ''));
            const actualBuyerExtended = parseFloat(actualBuyingPricePerUnit * data.qty);
            lineWeight = actualBuyerExtended / data.descriptionObj.buyerPricing_LB.replace(/[\$,]/g, "");
        } else {
            lineWeight = data.extendedValue / data?.descriptionObj?.Buyer_Pricing_LB.replace(/[\$,]/g, "");
        }
        totalOrderWeights = +(formatToTwoDecimalPlaces(totalOrderWeights).replace(/[\$,]/g, "")) + +(formatToTwoDecimalPlaces(lineWeight).replace(/[\$,]/g, ""));
    });

    return totalOrderWeights;
}

