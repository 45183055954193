// @ts-nocheck
import { useGlobalStore } from "@bryzos/giss-ui-library";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useOurLogin = () => {
  return useMutation(async (payload) => {
    let url = `${import.meta.env.VITE_API_SERVICE}/user/login`;
    const response = axios.post(url, payload, {
      timeout: 10000
    });
    return await response;
  },
  {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
    onError(error) {
      const store = useGlobalStore.getState();
      if (error.code === 'ERR_NETWORK_CHANGED' || error.code === "ERR_NETWORK" || error.code === 'ECONNABORTED' || error.message.includes('Network')) {
        store.setApiFailureDueToNoInternet(true);
      }else{
        return error
      }
    }
  }
  );
};

export default useOurLogin;
