import styles from './DotPulseLoader.module.scss';

const DotPulseLoader = (props:any) => {
    return (
        <>
          {
          (props.showLoader === true) &&
            <>
              <div className={styles.backDrop}></div>
              <div className={styles.LoaderMain}>
                <div className={styles.dotPulse}></div>
              </div>
            </>
          }
        </>
    );
};

export default DotPulseLoader;