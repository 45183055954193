import { Fade, Tooltip } from "@mui/material";
import { get2DigitFormater, get4DigitFormater } from "../../helper";
import { priceFeedBakTooltip } from "../../tooltip";
import { ReactComponent as DownWhiteArrow } from '../../assets/images/downArrowSelected.svg';
import { ReactComponent as DownWhiteDArrow } from '../../assets/images/downArrow.svg';
import { ReactComponent as UpWhiteArrow } from '../../assets/images/upArrowSelected.svg';
import { ReactComponent as UpWhiteDArrow } from '../../assets/images/upArrow.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/checkIcon.svg';
import { ReactComponent as CheckDIcon } from '../../assets/images/unCheck.svg';
import axios from "axios";
import { useEffect, useState } from "react";
import DialogPopup from "../../component/DialogPopup/DialogPopup";
import { commomKeys } from "../../../common";
import { ProductPricingModel, ProductQtyType } from "src/renderer/types/Search";
import { useGlobalStore, prizeFormatter, handlePriceRatingChange } from '@bryzos/giss-ui-library';

type ProductPricingState = {
    isUserChangedSelectedOption: boolean,
    selectedOption: ProductQtyType,
    product: ProductPricingModel
};

const ProductPricing: React.FC<ProductPricingState>  = ({selectedOption, isUserChangedSelectedOption, product}) => {
    const { userData, setUserData } = useGlobalStore();
    const initialFeedBackData = userData?.feedBackData ?? {} ;
    const [feedbackData, setFeedBackData] = useState(initialFeedBackData);
    //Dialog Popup 
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    const [showDialogPopup, setShowDialogPopup] = useState(false);

    useEffect(()=>{
        if(userData.selectedProducts && userData.selectedProducts.length === 0)
        setFeedBackData({});
    }, [userData.selectedProducts])

    const onChangePriceRateFeedback = (rating:string) => {
        handlePriceRatingChange(product, selectedOption, rating, isUserChangedSelectedOption,feedbackData, setFeedBackData, showOnError);
    };

    const showOnError = () => {
        setShowDialogPopup(true)
        setDialogType("");
        setDialogTitle("");
        setDialogContent(commomKeys.errorContent)
        setDialogBtnTitle(commomKeys.errorBtnTitle)
    }

    return <div className='priceRating'>
        <div>
            <p className='selectPrize'>
                <span className='doller'>$</span>
                <span className='prize'>{prizeFormatter(product, isUserChangedSelectedOption, selectedOption)}</span>
            </p>
        </div>

        <div className={'productFeedback'}>
        {(!product.is_safe_product_code) &&
            <Tooltip
                title={priceFeedBakTooltip()}
                arrow
                placement={'top-end'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'priceFeedBakTooltip',
                }}
            >
                <div className='priceFeedback'>
                    <label className={feedbackData[product.id] === 'HIGH' ? '' : 'hightBtn'}>
                        <input
                            type="radio"
                            value="HIGH"
                            checked={feedbackData[product.id] === 'HIGH'}
                            onChange={(event) => onChangePriceRateFeedback(event.target.value)}
                        />
                        {feedbackData[product.id] === 'HIGH' ? (
                            <UpWhiteArrow />
                        ) : (
                            <UpWhiteDArrow />
                        )}
                    </label>
                    <label className={feedbackData[product.id] === 'GOOD' ? 'goodBtn' : 'CheckBtn'}>
                        <input
                            type="radio"
                            value="GOOD"
                            checked={feedbackData[product.id] === 'GOOD'}
                            onChange={(event) => onChangePriceRateFeedback(event.target.value)}
                        />
                        {feedbackData[product.id] === 'GOOD' ? (
                            <span className='checkDone'>
                                <CheckIcon />
                            </span>
                        ) : (
                            <span>
                                <span className='img1'><CheckDIcon /></span>
                                <span className='img2'><CheckIcon /></span>
                            </span>
                        )}
                    </label>
                    <label className={feedbackData[product.id] === 'LOW' ? '' : 'lowBtn'}>
                        <input
                            type="radio"
                            value="LOW"
                            checked={feedbackData[product.id] === 'LOW'}
                            onChange={(event) => onChangePriceRateFeedback(event.target.value)}
                        />
                        {feedbackData[product.id] === 'LOW' ? (
                            <DownWhiteArrow />
                        ) : (
                            <DownWhiteDArrow />
                        )}
                    </label>

                </div>
            </Tooltip>
        }
        </div>
        
        <DialogPopup
                dialogTitle={dialogTitle}
                dialogContent={dialogContent}
                dialogBtnTitle={dialogBtnTitle}
                type={dialogType}
                open={showDialogPopup}
                onClose={() => setShowDialogPopup(false)}
                />
    </div>
}

export default ProductPricing;