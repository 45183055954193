// @ts-nocheck
import {  routes } from '../../../common';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { changePasswordConst, commomKeys, decryptData, encryptData, useGlobalStore, usePostMigrateToPassword, getChannelWindow, useGlobalSignoutApi, channelWindowList } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import useDialogStore from '../DialogPopup/DialogStore';
import styles from './changePassword.module.scss'
import { clsx } from 'clsx';
import { ReactComponent as ErrorEmailIcon } from '../../assets/images/errorEmail.svg';
import { ReactComponent as ShowPassIcon } from '../../assets/images/show-pass.svg';
import { ReactComponent as HidePassIcon } from '../../assets/images/hide-pass.svg';
import { Fade, Tooltip } from '@mui/material';

const ChangePassword = ({closeDialog, getDeviceId}) => {
    const channelWindow = getChannelWindow();
    const navigate = useNavigate();
    const isChangePasswordModule = (location.pathname === routes.changePassword);
    const { decryptionEntity, userData, setGlobalForceLogout, setAutoLogin, isManualLogin } = useGlobalStore();
    const { showCommonDialog, resetDialogStore } = useDialogStore();
    const changePasswordConfirmation = usePostMigrateToPassword();
    const [showOldPassword, setShowOldPassword] = useState(true);
    const [passwordVisibility, setPasswordVisibility] = useState({
        password1: true,
        password2: true,
        password3: true,
    });
    const globalSignout = useGlobalSignoutApi();
    const [isEdited,setIsEdited] = useState(false);

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };
    const { register, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(
            yup.object({
                currentPassword: yup.string().test('len', 'Password must be 6 digits', val => val?.length >= 6),
                newPassword: yup.string().required('Password is required').min(6,'Password must be 6 digits'),
                confirmPassword: yup.string().test("isRequired", "Password does not match!", function (value) {
                    const password = this.parent.newPassword;
                    if (password.trim() === value.trim()) return true;
                    return false;
                })
            }).required()
        ),
        mode: 'onSubmit',
    });

    useEffect(() => {
        (async () => {
            if (channelWindow?.getLoginCredential && decryptionEntity) {
                const cred = window.electron.sendSync({ channel: channelWindow.getLoginCredential });
                if (cred && isChangePasswordModule && isManualLogin ) {
                    const data = JSON.parse(await decryptData(cred, decryptionEntity.decryption_key, true));
                    setValue('currentPassword', data.password);
                    setShowOldPassword(false);
                }
            }
        })()
    }, [])

    async function submit({ newPassword, currentPassword }) {
        try {
            if (userData) {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, currentPassword, newPassword);
                const email = userData.data?.email_id;
                if (channelWindow?.setLoginCredential && email && newPassword && decryptionEntity && isChangePasswordModule) {
                    const _encryptCredential = await encryptData(JSON.stringify({ email, password: newPassword }), decryptionEntity.decryption_key);
                    window.electron.send({ channel: channelWindow.setLoginCredential, data: _encryptCredential });
                }
                if(isChangePasswordModule)
                changePasswordConfirmation.mutateAsync(email).then(async () => {
                    onChangePassword(email);
                })
                else
                onChangePassword(email);
            }
        } catch (error) {
            if (error.message === changePasswordConst.changePasswordCognitoError) error.message = changePasswordConst.incorrectOldPasswordError;
            showCommonDialog(null, error.message ?? changePasswordConst.onError, null, resetDialogStore, [{ name: commomKeys.errorBtnTitle, action: resetDialogStore }])
        }
    }

    const onChangePassword = async (email_id) => {
        try{
            await globalSignout.mutateAsync({data:{email_id,device_id: await getDeviceId()}});
        }catch(err){
            console.error(err)
        }
        finally{
            if(closeDialog) closeDialog();
            showCommonDialog(null, changePasswordConst.onSuccess, null, resetDialog, [{ name: commomKeys.continue, action: resetDialog }]);
        }
    }

    const resetDialog = () => {
        resetDialogStore();
        if(isChangePasswordModule) setAutoLogin(true);
        else setGlobalForceLogout(routes.loginPage);
    }

    const handleForgotPassword = () => {
        showCommonDialog(commomKeys.warning, changePasswordConst.forgotPasswordPrompt, null, resetDialog, [{ name: commomKeys.yes, action: naviagateToForgotPassword },{ name: commomKeys.no, action: resetDialogStore }]);
    }

    const naviagateToForgotPassword = () => {
        resetDialogStore();
        setGlobalForceLogout(routes.forgotPassword);
    }

    const handlePasswordBlur = () => {
        const newPassword = getValues('newPassword')?.trim();
        const confirmPassword = getValues('confirmPassword')?.trim();
        if(newPassword.length && confirmPassword?.length){
            if (newPassword === confirmPassword) {
              clearErrors(["newPassword", "confirmPassword"]);
            } else{ 
                setError("newPassword", { message: "Password does not match!" });
            }
        }
      }
   
    return (
        <div className={clsx(styles.resetPasscontainer, (!isChangePasswordModule && styles.changePassPopup))}>
            <p className={styles.resetPassTitle}>
                {(!isChangePasswordModule) ?  'Change your password' : 'Set Your New Password'}
            </p>
            {isChangePasswordModule &&
                <p className={styles.noteText}>
                    {userData?.data?.is_migrated_to_password === 0 ?
                        "As part of our recent system improvements, we’re asking you to set a new password for enhanced security. Please take a moment to update your credentials below."
                        :
                        "We need you to reset your password since it was recently reset by an admin. Please take a moment to update your credentials below for continued access."
                    }
                </p>
            }
            <div className={styles.changePassInnerContent}>
                {showOldPassword && <div className={styles.currentPassword}>
                    <div className={clsx(styles.FormInputGroup)}>
                        <span className={styles.lblInput}>Current Password</span>
                        <Tooltip
                            title={errors.currentPassword?.message}
                            arrow
                            placement={"top"}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 100 }}
                            classes={{
                                tooltip: "inputQtyTooltip",
                            }}
                        >
                            <span className={clsx(styles.inputSection, (errors?.currentPassword) && styles.errorEmail)}>
                                <input type={passwordVisibility.password1 ? 'password' : 'text'} {...register("currentPassword")} placeholder='Enter your current password'
                                    onChange={(e) => {
                                        e.target.value = e.target.value.trim();
                                        register('currentPassword').onChange(e);
                                    }}
                                />
                                <button className={styles.showPassBtn} onClick={() => togglePasswordVisibility('password1')}>
                                    {passwordVisibility.password1 ? <ShowPassIcon /> : <HidePassIcon />}
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                    <span className={styles.forgotPasswordTxt} onClick={handleForgotPassword}>Forgot Password?</span>
                </div>}

                <div className={styles.passwordErrorContainer}>
                    <div className={clsx(styles.FormInputGroup)}>
                        <span className={styles.lblInput}>New Password</span>
                        <Tooltip
                            title={errors?.newPassword?.message || errors?.confirmPassword?.message}
                            arrow
                            placement={"top"}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                            classes={{
                                tooltip: "inputQtyTooltip",
                            }}
                        >
                            <span className={clsx(styles.inputSection, (errors?.newPassword || errors?.confirmPassword) && styles.errorEmail)}>
                                <input type={passwordVisibility.password2 ? 'password' : 'text'} {...register("newPassword")} placeholder='Enter your new password'
                                    onChange={(e) => {
                                        e.target.value = e.target.value.trim();
                                        register('newPassword').onChange(e);
                                        setIsEdited(true)
                                    }}
                                    onBlur={(e) => {
                                        register("newPassword").onBlur(e);
                                        handlePasswordBlur()
                                    }} 
                                />
                                <button className={styles.showPassBtn} onClick={() => togglePasswordVisibility('password2')}>
                                    {passwordVisibility.password2 ? <ShowPassIcon /> : <HidePassIcon />}
                                </button>
                            </span>
                            </Tooltip>

                    </div>

                    <div className={clsx(styles.FormInputGroup)}>
                        <span className={styles.lblInput}>Confirm Password</span>
                        <span className={clsx(styles.inputSection, (errors?.newPassword || errors?.confirmPassword) && styles.errorEmail)}>
                            <input type={passwordVisibility.password3 ? 'password' : 'text'} {...register("confirmPassword")} placeholder='Confirm password'
                                onChange={(e) => {
                                    e.target.value = e.target.value.trim();
                                    register('confirmPassword').onChange(e);
                                    setIsEdited(true)
                                }}
                                onBlur={(e) => {
                                    register("confirmPassword").onBlur(e);
                                    handlePasswordBlur()
                                }}
                            />
                            <button className={styles.showPassBtn} onClick={() => togglePasswordVisibility('password3')}>
                                {passwordVisibility.password3 ? <ShowPassIcon /> : <HidePassIcon />}
                            </button>
                        </span>
                    </div>

                    {(errors?.newPassword || errors?.confirmPassword) &&
                        <div className={styles.errorBorder}>
                            <ErrorEmailIcon />
                        </div>
                    }
                </div>

            </div>
            <div className={styles.btnSection}>
                {!isChangePasswordModule && <button className={styles.btnReset} onClick={()=> closeDialog && closeDialog()}>Cancel</button>}
                <button className={clsx(styles.btnReset,styles.saveBtnChangePass)} onClick={() => { handleSubmit(submit)() }} disabled={!isEdited || Object.keys(errors).length > 0 }>Save</button>
            </div>
        </div>
    );
};

export default ChangePassword;