import { commonAppEventsofPusher, getChannelWindow, getSocketConnection, loginPageConst, removeAxiosInterceptor, resetConnection, useGlobalStore, useSellerOrderStore, forceLogoutConst, changePasswordConst , useAuthStore,decryptData } from "@bryzos/giss-ui-library";
import axios from 'axios';
import rg4js from "raygun4js";
import { useEffect, useState } from "react";
import { commomKeys, routes, snackbarSeverityType } from "src/common";
import useGetForbiddenTooltips from "src/renderer/hooks/useGetForbiddenTooltips";
import useOurLogin from "src/renderer/hooks/useOurLogin";
import useSnackbarStore from "../Snackbar/snackbarStore";
import { useLocation, useNavigate } from "react-router-dom";
import { navigatePage } from "src/renderer/helper";
import clearPageSessionData from "src/renderer/utility/clearPageSessionData";
import { Auth } from 'aws-amplify';
import useDialogStore from "../DialogPopup/DialogStore";
import useOnSubmitLogin from "src/renderer/hooks/useOnSubmitLogin";

const AuthenticationWrapper = ({
  addSecurityHashInInterceptor, getDeviceId, cognitoUser, removeSocketDisconnectToaster, getRefenceData, createSocketConnection, showCustomNotification, readNotificationAsRead
  , foregroundBackgroundActivityPayloadRef, showOverlayToaster, children
}) => {
  const { userData, setUserData, setAppVersion, resetCommonStore, setShowChatIcon,  setUserCredentials,  setUserAccessToken, setCurrentAccessToken, currentAccessToken, systemVersion, appVersion, originalLoggedInUserData, setOriginalLoggedInUserData, isImpersonatedUserLoggedIn, setIsImpersonatedUserLoggedIn, setHasLoginProcessCompleted, hasLoginProcessCompleted, startLoginProcess, setStartLoginProcess } = useGlobalStore();
  const { setBackdropOverlay, forceLogout, setForceLogout, setIsAppReadyToUseInState, autoLogin, setAutoLogin, passwordChanged, setPasswordChanged, setTriggerExitImpersonation, triggerExitImpersonation, setHideHeaderState, setCurrentUser, globalForceLogout, setGlobalForceLogout, globalLogout, setGlobalLogout , isAppReadyToUseInState , isUserLoggedIn , securityHash, setViewedOrdersListForBadgeCount } = useGlobalStore();
  const { setTriggerPartialLogout, triggerPartialLogout, triggerImpersonation, defaultLoginImpersonateUserData, resetAuthCommonStore, resetDefaultLoginImpersonateUserData,triggerLogout, defaultLogoutData, resetDefaultLogoutData, setLoginError,  triggerSubmitLogin, resetSubmitLogin, submitLoginData } = useAuthStore()
  const setShowLoader = useGlobalStore(state => state.setShowLoader);
  const channelWindow = getChannelWindow();
  const ourLogin = useOurLogin();
  const setForbiddenTooltips = useGlobalStore(state => state.setForbiddenTooltips);
  const {resetSnackbarStore } = useSnackbarStore();
  const { resetOrderStore } = useSellerOrderStore();
  const resetHeaderConfig = useGlobalStore(state => state.resetHeaderConfig);
  const createPoSessionId = useGlobalStore(state => state.createPoSessionId);
  const setCreatePoSessionId = useGlobalStore(state => state.setCreatePoSessionId);
  const setResetHeaderConfig = useGlobalStore(state => state.setResetHeaderConfig);
  const setDisableBryzosNavigation = useGlobalStore(state => state.setDisableBryzosNavigation);
  const setSystemVersion = useGlobalStore(state => state.setSystemVersion);
  const setIsUserLoggedIn = useGlobalStore(state => state.setIsUserLoggedIn);
  const userCredentials = useGlobalStore(state => state.userCredentials);
  const decryptionEntity = useGlobalStore(state => state.decryptionEntity);
  const { resetDialogStore } = useDialogStore();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [resumeApp, setResumeApp] = useState(false);
  const onSubmitLogin =  useOnSubmitLogin(setLoginError);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    refetch: getForbiddenTooltips,
    data: forbiddenTooltipsData,
  } = useGetForbiddenTooltips();

  useEffect(() => {
    if(location.pathname !== routes.newUpdate ){
      if (channelWindow?.resumeApp) {
        window.electron.receive(channelWindow.resumeApp, () => {
          setResumeApp(true);
        })
      }
      getUserCred();
    }
    setIsAppReadyToUseInState(true);
      return () => {
        setLoginError();
        resetDialogStore();
      }
  }, [])

  useEffect(()=>{
    if(userCredentials && decryptionEntity && location.pathname === routes.loginPage){
        if(userCredentials === changePasswordConst.noUserCredentialFound){
            setIsUserLoggedIn(true);
            setStartLoginProcess(true);
        }
        else{
            decryptUserCredential(userCredentials, decryptionEntity);
            setUserCredentials(null);
        }
    }
  },[userCredentials, decryptionEntity , location.pathname])

  useEffect(() => {
    if (triggerExitImpersonation) {
      exitImpersonation();
      setTriggerExitImpersonation(false);
      resetSnackbarStore();
    }
  }, [triggerExitImpersonation])

  useEffect(() => {
    if (resetHeaderConfig) {
      removeAxiosInterceptor();
      resetCommonStore();
      resetAuthCommonStore()
      setCurrentUser(null)
      addSecurityHashInInterceptor(true);
      foregroundBackgroundActivityPayloadRef.current = null;
      setForceLogout(false);
    }
  }, [resetHeaderConfig]);

  useEffect(() => {
    if (startLoginProcess && !!cognitoUser && location.pathname !== routes.newUpdate) {
      clickOnEnterSession(cognitoUser.attributes.email);
    }
  }, [cognitoUser])

  useEffect(()=>{
    if(triggerSubmitLogin){
      submit(submitLoginData)
      resetSubmitLogin()
    }
  })

  const getUserCred = () => {
    if (channelWindow?.fetchOnRendererMount) {
        const systemData = window.electron.sendSync({ channel: channelWindow.fetchOnRendererMount });
        if(systemData.autoLoginCredential){
          setShowLoader(true)
          setUserCredentials(systemData.autoLoginCredential)
        }else{
          setUserCredentials(changePasswordConst.noUserCredentialFound)
        }
        setSystemVersion(systemData.systemVersion);
    } else {
        setUserCredentials(changePasswordConst.noUserCredentialFound)
        setShowLoader(false)
    }
  }

  useEffect(() => {
      if (resumeApp) {
        if(location.pathname === routes.loginPage)
        getUserCred();
        setResumeApp(false);
      }
  }, [resumeApp])

  const decryptUserCredential = async (userCredentials, decryptionEntity) => {
    const data = JSON.parse(await decryptData(userCredentials, decryptionEntity.decryption_key, true));
    submit(data);
  }

  async function submit(data) {
    setShowLoader(true);
    setStartLoginProcess(true);
    const password = data.password;
    const email = data.email;
    const payload = { email, password };
    onSubmitLogin.mutateAsync(payload);
  }

  useEffect(() => {
    if (autoLogin) {
      refreshAndAutoLogin();
      setAutoLogin(false);
    }
  }, [autoLogin])

  useEffect(() => {
    if (passwordChanged) {
      if (passwordChanged.device_id !== getDeviceId()) {
        if (!isImpersonatedUserLoggedIn) {
          if (userData.data.email_id === passwordChanged.email_id) setGlobalForceLogout(routes.loginPage);
        }
        else {
          if (passwordChanged.email_id === originalLoggedInUserData.email_id) {
            setGlobalForceLogout(routes.loginPage);
            setIsImpersonatedUserLoggedIn(false);
          }
          else if (userData.data.email_id === passwordChanged.email_id) {
            showOverlayToaster(changePasswordConst.exitImpersonationContent, snackbarSeverityType.warning, [{ name: commomKeys.errorBtnTitle, handler: exitImpersonation }], null, true);
          }
        }
      }
      setPasswordChanged(null);
    }
  }, [passwordChanged])

  useEffect(() => {
    if (forceLogout && isImpersonatedUserLoggedIn) {
      showOverlayToaster(userData.data.email_id + forceLogoutConst.impersonateForceLogoutContent, snackbarSeverityType.warning, [{ name: commomKeys.errorBtnTitle, handler: resetUser }], null, true);
      setForceLogout(false);
    }
  }, [forceLogout])

  useEffect(() => {
    if (triggerPartialLogout) {
      partialLogout()
    }
  }, [triggerPartialLogout])

  useEffect(() => {
    const impersonateUser = async () => {
      if (triggerImpersonation) {
        await loginImpersonatedUser(defaultLoginImpersonateUserData.token, defaultLoginImpersonateUserData.impersonateUserData);
        resetDefaultLoginImpersonateUserData()
      }
    };

    impersonateUser();
  }, [triggerImpersonation, defaultLoginImpersonateUserData]);

  useEffect(() => {
    if (forceLogout && !isImpersonatedUserLoggedIn) {
      handleLogout(true, false, false);
      setForceLogout(false);
    }
  }, [forceLogout])

  useEffect(() => {
    (async () => {
      if (globalForceLogout) {
        await handleLogout(true, true, true);
        navigatePage(location.pathname, { path: globalForceLogout });
        setGlobalForceLogout(null);
      }
    })();
  }, [globalForceLogout])

  useEffect(() => {
    (async () => {
      if (globalLogout) {
        await handleLogout(false, true, true);
        navigatePage(location.pathname, { path: globalLogout });
        setGlobalLogout(null);
      }
    })();
  }, [globalLogout])

  useEffect(() => {
    (async () => {
      if (triggerLogout) {
        await handleLogout(defaultLogoutData.forceLogout, defaultLogoutData.stopNavigate, defaultLogoutData.removePassword)
        resetDefaultLogoutData()
      }
    })();
  }, [triggerLogout, defaultLogoutData])

  useEffect(()=>{
    if(!cognitoUser && location.pathname !== routes.newUpdate && appVersion){
      let showLoginPage = true;
      if(channelWindow?.windowStore)
        showLoginPage = window.electron.sendSync({ channel: channelWindow.windowStore, data: 'check'})
      if(!showLoginPage){
        navigate(routes.onboardingWelcome)
      }else{
        navigate(routes.loginPage)
      }
    }
  },[cognitoUser, appVersion])

  useEffect(() => {
    setForbiddenTooltipsData(forbiddenTooltipsData)
  }, [forbiddenTooltipsData]);

  const setForbiddenTooltipsData = (forbiddenTooltips) => {
    const _forbiddenTooltipsData = Array.isArray(forbiddenTooltips) ? forbiddenTooltips : [];
    setForbiddenTooltips(_forbiddenTooltipsData);
  }; 

  const setUserJsonData = (jsonData) => {
    const badgeCountReadyToClaimList = jsonData.viewedOrdersListForBadgeCount
    if( badgeCountReadyToClaimList ) {
      const viewedOrderList = JSON.parse(badgeCountReadyToClaimList);
      setViewedOrdersListForBadgeCount(viewedOrderList ?? []);
    }
  }

  const handleLogout = async (isForceLogout, stopNavigate = false, removePassword = false) => {
    if (!isLoggingOut && hasLoginProcessCompleted && userData.data) {
      resetSnackbarStore();
      setStartLoginProcess(false);
      setUserCredentials(null);
      setBackdropOverlay(false)
      setIsLoggingOut(true);
      setHideHeaderState(true)
      if (!isForceLogout) {
        let payload = {
          data: {
            'email_id': isImpersonatedUserLoggedIn ? originalLoggedInUserData?.email_id : userData?.data?.email_id
          }
        }
        const pageSessionData = {
          createPoSessionId: createPoSessionId,
        }
        await clearPageSessionData(location.pathname, pageSessionData);
        if (channelWindow?.systemVersion) {
          const systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null })
          payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
          payload.data.os_version = systemVersion;
          payload.data.last_login_app_version = appVersion;
        }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/logout', payload)
          .finally(async () => {
            await clearOnLogout(removePassword, stopNavigate);
          })
      }
      else await clearOnLogout(removePassword, stopNavigate);
    }
  }

  const clearOnLogout = async (removePassword, stopNavigate) => {
    try {
      await Auth.signOut();
      setCreatePoSessionId(null)
    } catch (error) {
      console.error('error signing out: ', error);
    }
    setUserData({});
    if (channelWindow?.handleLogout && removePassword)
      window.electron.send({ channel: channelWindow.handleLogout });
    setTriggerPartialLogout(true)
    if (channelWindow?.logout)
      window.electron.send({ channel: channelWindow.logout });
    if (channelWindow?.badgeCountHandler)
      window.electron.send({ channel: channelWindow.badgeCountHandler, data: { type: 'reset' } });
    setIsLoggingOut(false);
    setHideHeaderState(false)
    setUserData({ data: null });
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    setResetHeaderConfig(true);
    setDisableBryzosNavigation(true);
    if (!stopNavigate)
      navigatePage(location.pathname, { path: routes.loginPage })
    setShowLoader(false);
  }

  async function clickOnEnterSession(emailData, disableAccessToken = false, accessToken = null, userData = null) {
    setShowLoader(true);
    setHasLoginProcessCompleted(false);
    await addSecurityHashInInterceptor(disableAccessToken, accessToken, userData)
    const deviceId = getDeviceId();

    let payload = {
      data: { "email_id": emailData, device_id: deviceId }
    }
    if (channelWindow?.systemVersion) {
      payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
      payload.data.os_version = systemVersion;
      payload.data.last_login_app_version = appVersion;
    }
    ourLogin.mutateAsync(payload).then(async (response) => {
      let userDetail = response.data.data;
      userDetail.isExistingUser = true;
      if (typeof userDetail === 'string') {
        clickOnEnterSession(emailData)
        setShowLoader(false);
        return;
      }
      setUserData({ data: userDetail })
      setAppVersion(appVersion);
      setShowChatIcon(false);
      rg4js('setUser', {
        firstName: userDetail.first_name,
        lastName: userDetail.last_name,
        email: userDetail.email_id,
      });
      const userExists = userDetail.id;
    
      if (userExists) {
        getForbiddenTooltips().then(({data:forbiddenTooltipsData})=>{
          setForbiddenTooltipsData(forbiddenTooltipsData)
        });;
        if (channelWindow?.windowStore)
          window.electron.send({ channel: channelWindow.windowStore, data: 'set' })
        const isImpersonated = accessToken ? true : false;
        await getRefenceData(userDetail, isImpersonated);
        removeSocketDisconnectToaster();
        setHasLoginProcessCompleted(true);
        setCurrentUser(userDetail)
        await createSocketConnection(userDetail, accessToken);
        if (!isImpersonated) {
          if (channelWindow?.pusher) window.electron.send({ channel: channelWindow.pusher, data: { user: userDetail, pusherId: userDetail.pusher_id, emailId: emailData, accessToken: currentAccessToken } });
          axios.get(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/getAllUnreadNotification ', payload)
            .then(res => {
              if (res.data.data) {
                const _notificationList = res.data.data.notificationList;
                if (_notificationList && Array.isArray(_notificationList)) {
                  const filterNotification = _notificationList.filter(notificationData => {
                    const notificationObj = notificationData.notification;
                    if (notificationObj.event !== commonAppEventsofPusher.mobileEvents.capgoUpdate) {
                      return notificationData;
                    }
                  });

                  if (channelWindow?.showNotification && filterNotification?.length !== 0)
                    window.electron.send({ channel: channelWindow.showNotification, data: filterNotification });
                }
                setUserJsonData(res.data.data);
              }
            })
            .catch(err => console.error(err));
          try {
            const deviceId = getDeviceId();

            const customNotificationList = (await axios.get(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/getCustomNotification', { params: { device_id: deviceId } })).data?.data;
            if (customNotificationList?.length) {
              const notificationList = customNotificationList.map(notificationSchema => notificationSchema.notification);
              readNotificationAsRead(notificationList,true);
              const lastCustomNotification = customNotificationList[customNotificationList.length - 1];
              showCustomNotification(lastCustomNotification);
            }
          } catch (error) { }
        }
        setStartLoginProcess(false);
      }
    })
      .catch(error => {
        setShowLoader(false);
        setCurrentUser(null)
        if (error?.response?.status === 400) {
          setLoginError(loginPageConst.loginApprovalError)
          setForceLogout(true);
        }
      });

  }

  const loginImpersonatedUser = (accessToken, impersonatedUserData) => {
    if (accessToken && impersonatedUserData) {
      partialLogout();
      setHideHeaderState(false)
      setCurrentAccessToken(accessToken)
      setUserAccessToken(currentAccessToken);
      setOriginalLoggedInUserData(userData.data);
      setIsImpersonatedUserLoggedIn(true);
      clickOnEnterSession(impersonatedUserData.email_id, true, accessToken, userData)
    }
  }

  const partialLogout = () => {
    setUserData({});
    setForbiddenTooltips([]);
    resetSnackbarStore();
    setBackdropOverlay(false)
    if (channelWindow?.badgeCountHandler)
      window.electron.send({ channel: channelWindow.badgeCountHandler, data: { type: 'reset' } });
    getSocketConnection()?.disconnect();
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key !== "isSticky" && key !== "localStorageStickyItemKey") {
        sessionStorage.removeItem(key);
      }
    }
    resetConnection();
    resetOrderStore();
    setHasLoginProcessCompleted(false);
  }

  const refreshAndAutoLogin = () => {
    if (channelWindow?.fetchOnRendererMount) {
      partialLogout();
      setShowLoader(true);
      const systemData = window.electron.sendSync({ channel: channelWindow.fetchOnRendererMount });
      setUserCredentials(systemData.autoLoginCredential ?? changePasswordConst.noUserCredentialFound);
      navigate(routes.loginPage);
    } else {
      setShowLoader(false)
    }
  }

  const resetUser = () => {
    resetSnackbarStore()
    partialLogout();
    resetCommonStore();
    resetAuthCommonStore()
    setShowLoader(true)
    if (channelWindow?.refreshApp) {
      window.electron.send({ channel: channelWindow.refreshApp });
    } else {
      setShowLoader(false)
    }
  }

  const exitImpersonation = async () => {
    setShowLoader(true);
    resetSnackbarStore()
    let payload = {
      data: {
        'email_id': userData.data.email_id
      }
    }
    if (channelWindow?.systemVersion) {
      const systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null })
      payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
      payload.data.os_version = systemVersion;
      payload.data.last_login_app_version = appVersion;
    }
    navigate(routes.loginPage);
    axios.post(import.meta.env.VITE_API_SERVICE + '/user/logout', payload)
      .then(() => {
        resetUser()
      })

  }

  return <>
   {!((!securityHash) || (location.pathname !== routes.onboardingWelcome 
    && location.pathname !== routes.onboardingDetails 
    && location.pathname !== routes.onboardingTnc
    && location.pathname !== routes.onboardingThankYou
    && location.pathname !== routes.loginPage 
    && location.pathname !== routes.newUpdate 
    && location.pathname !== routes.forgotPassword && !isAppReadyToUseInState)
    )&& children}
    </>
}
export default AuthenticationWrapper;