import { useQuery } from '@tanstack/react-query';
import { reactQueryKeys } from '../../common';
import axios from 'axios';
import useDialogStore from '../component/DialogPopup/DialogStore';
import { commomKeys } from '@bryzos/giss-ui-library';

const useGetCompanyLists = (isEnabled = true) => {
  const { showCommonDialog, resetDialogStore } = useDialogStore();
  return useQuery([reactQueryKeys.getCompanyLists], async () => {
    try {
      let url = `${import.meta.env.VITE_API_SERVICE}/user/company`;

      const response = axios.get(url);
      const responseData = await response;

      if (responseData.data && responseData.data?.data) {
        return responseData.data.data;
      } else {
        return null;
      }
    } catch (error: any) {
      showCommonDialog(null, commomKeys.errorContent, null, resetDialogStore, [{name: commomKeys.errorBtnTitle, action: resetDialogStore}])
    }
  },{
    enabled: isEnabled
  });
};

export default useGetCompanyLists;
