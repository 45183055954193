// @ts-nocheck
import { useEffect, useState } from 'react';
import styles from './videoLibrary.module.scss';
import { useGetVideoByTag, useGetAllVideoLibraryTag, useImgixOrImageKit, useGlobalStore, commomKeys, USE_IMGIX, USE_IMAGEKIT, useSaveVideoLibraryViews, formatCurrencyWithComma } from '@bryzos/giss-ui-library';
import VideoPlayer from 'src/renderer/component/videoPlayer/videoPlayer';
import { ReactComponent as VideoPlayIcon } from '../../assets/images/VideoPlay-Icon.svg';
import { ReactComponent as ViewsIcon } from '../../assets/images/Views.svg';
import { ReactComponent as ShareVideo } from '../../assets/images/ShareVideo.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';
import useDialogStore from '../../component/DialogPopup/DialogStore';
import clsx from 'clsx';
import { routes } from 'src/common';
import { useNavigate } from 'react-router-dom';
import ShareVideoUrl from 'src/renderer/component/ShareVideoUrl/ShareVideoUrl';
import DotPulseLoader from 'src/renderer/component/DotPulseLoader/DotPulseLoader';

const VideoLibrary = () => {
  const getVideoByTag = useGetVideoByTag();
  const getAllVideoLibraryTag = useGetAllVideoLibraryTag();
  const imgixOrImageKit = useImgixOrImageKit();
  const [videoLibraryTags, setVideoLibraryTags] = useState([]);
  const [targetedVideoToPlay, setTargetedVideoToPlay] = useState();
  const [targetedTag, setTargetedTag] = useState();
  const [targetedIndex, setTargetedIndex] = useState();
  const [playVideoUrl, setPlayVideoUrl] = useState();
  const [captionUrl, setCaptionUrl] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [useImgixUrl, setUseImgixUrl] = useState(false);
  const [useImageKitUrl, setUseImageKitUrl] = useState(false);
  const [videoMapping, setVideoMapping] = useState({});
  const { setShowLoader, userData, backNavigation, setVideoLibraryMapping, videoLibraryMapping, setVideoLibraryViewCount, videoLibraryViewCount, videoLibraryTags : tagsFromLibrary, setVideoLibraryTags: setTagsFromLibrary } = useGlobalStore();
  const { showCommonDialog, resetDialogStore } = useDialogStore();
  const saveVideoViewCount = useSaveVideoLibraryViews();
  const [videoNoLongerAvailable, setVideoNoLongerAvailable] = useState(false);
  const [disableNextVideoBtn, setDisableNextVideoBtn] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageLoading,setImageLoading]= useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    getAllVideos();
  }, [])

  useEffect(()=>{
    setImageLoading(true)
  },[thumbnailUrl])

  useEffect(()=>{
    if(tagsFromLibrary){
      const newTags = tagsFromLibrary.reduce((acc, current) => {
        const index = videoLibraryTags.findIndex((tag) => current.query_param === tag.query_param);
        if(index < 0){
          acc.push(current.query_param);
        }
        return acc;
      },[])
      if(newTags.length > 0)
      getVideoDataByTags(newTags)
      setVideoLibraryTags(tagsFromLibrary);
      setTagsFromLibrary(null);
      if(targetedTag){
        const currentPlayingVideoTag = tagsFromLibrary.find((tag)=> tag.query_param === targetedTag )
        setVideoNoLongerAvailable(!currentPlayingVideoTag?.show_on_app ?? true);
      }
    }
  },[tagsFromLibrary])

  useEffect(()=>{
    if(videoLibraryMapping){
      setVideoMapping(videoLibraryMapping);
      const index = videoLibraryMapping[targetedTag]?.findIndex(x => targetedVideoToPlay.video_id === x.video_id)
      if(targetedIndex >= 0) {
        if(index >= 0){
          const video = videoLibraryMapping[targetedTag][index];
          setTargetedIndex(index);
          setDisableNextVideoBtn(videoLibraryMapping[targetedTag].length <= 1);
          playVideo(targetedTag , video , index);
        }
        else{
          setVideoNoLongerAvailable(true);
          setTargetedIndex();
          setTargetedTag();
        }
      }
      setVideoLibraryMapping(null);
    }
  },[videoLibraryMapping])

  useEffect(()=>{
    if(videoLibraryViewCount){
      const {tag, view_count, video_id} = videoLibraryViewCount;
      if(videoMapping[tag])
      videoMapping[tag] = videoMapping[tag].map((video) => {
        if(video.video_id === video_id)return {...video, view_count};
        return video;
      });
      
      setVideoMapping({...videoMapping});
      setVideoLibraryViewCount(null);
    }
  },[videoLibraryViewCount])

  const getAllVideos = async () => {
    setShowLoader(true);
    try{
      const response = (await imgixOrImageKit.mutateAsync())?.data?.data;
      if(response){
        const imgixObj = response.find(res => res.config_key === USE_IMGIX);
        if (imgixObj) {
          setUseImgixUrl(imgixObj.config_value);
        }
        const imgeKitObj = response.find(res => res.config_key === USE_IMAGEKIT);
        if (imgeKitObj) {
          setUseImageKitUrl(imgeKitObj.config_value);
        }
      }
      const libraryTagsList = (await getAllVideoLibraryTag.mutateAsync('app')).data?.data;
      if(libraryTagsList){
        const tagList = libraryTagsList.reduce((tags ,curr_tag) => {
          tags.push(curr_tag.query_param);
          return tags;
        },[]);
        setVideoLibraryTags(libraryTagsList);
        getVideoDataByTags(tagList);
      }
    }
    catch(err){
      showCommonDialog(null, commomKeys.errorContent, null, resetDialogStore, [{name: commomKeys.errorBtnTitle, action: resetDialogStore}]);
      setShowLoader(false);
    }
  }
 
  const getVideoDataByTags = (tagList) => {
    getVideoByTag.mutateAsync(tagList).then((data)=>{
      if(data?.data?.data){
        const videoMappingData = {...videoMapping, ...data.data.data};
        setVideoMapping(videoMappingData);
      }
      setShowLoader(false);
    })
  }

  const getPlayVideoUrl = (s3Path, tag, video) => {
    let url = useImageKitUrl
      ? import.meta.env.VITE_IMAGEKIT_PREFIX +
        s3Path 
      : import.meta.env.VITE_CLOUDFRONT_PREFIX + s3Path ;
    url += '?tag=' + tag;
    if (video.is_large_file && useImageKitUrl) {
      url += '&tr=orig';
    }
    return url;
  };

  const playVideo = (tag: string, video: any, index: number) => {
    if(!(tag && videoMapping[tag]?.length > 1)){
      setDisableNextVideoBtn(true);
    }else{
      setDisableNextVideoBtn(false);
    }
    if (video?.video_s3_url) {
      const payload = {
        data: {
          user_id: userData.data.id.toString(),
          video_id: video.video_id,
        },
      };
      video.view_count += 1;
      saveVideoViewCount.mutateAsync(payload).then(() => {
        setVideoMapping({ ...videoMapping });
      });
      setVideoUrlForPlayer(video);
      const s3Path = video.video_s3_url.split(".com")[1];
      const _url = getPlayVideoUrl(s3Path, tag, video);
      setPlayVideoUrl();
      setCaptionUrl();

        setTimeout(() => {
            setPlayVideoUrl(_url);
            setCaptionUrl(video.subtitle_s3_url);
        } , 0)
    } else {
      setPlayVideoUrl();
      setCaptionUrl();
    }
    if(video?.thumbnail_s3_url_map?.electron_player){
      setVideoPlayerThumbnail(video);
    }
    setTargetedIndex(index);
    setTargetedTag(tag);
    setTargetedVideoToPlay(video);
    setVideoNoLongerAvailable(false);
    setIsExpanded(false) 
  }

  const goBack = () => {
    if(thumbnailUrl){
      setThumbnailUrl()
    }
    if(playVideoUrl) {
      setPlayVideoUrl();
    }
      setCaptionUrl();
      setTargetedIndex();
      setTargetedTag();
      setTargetedVideoToPlay();
      setVideoNoLongerAvailable(false);
    if(!(thumbnailUrl || playVideoUrl))
      navigate(routes.homePage);
  }

  const playNextVideo = () => {
    if(videoMapping[targetedTag]?.length > 1){
      const index = (targetedIndex+1) % (videoMapping[targetedTag].length);
      playVideo(targetedTag, videoMapping[targetedTag][index], index);
      
    }
  }

  const shortTextLimit = 130;
  const fullTextLimit = 180;
  const isLongText = videoMapping[targetedTag]?.[targetedIndex]?.description?.length > fullTextLimit;
  const truncatedText = videoMapping[targetedTag]?.[targetedIndex]?.description?.length > shortTextLimit ? videoMapping[targetedTag]?.[targetedIndex]?.description?.slice(0, shortTextLimit) + '...' : videoMapping[targetedTag]?.[targetedIndex]?.description;
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [openShare, setOpenShare] = useState(false);

  const openShareVideoPopup = () => {
    setOpenShare(true);
  };

  const shareVideoPopupClose = () => {
    setOpenShare(false);
  };

  const setVideoPlayerThumbnail = (video) => {
    const s3Path = video.thumbnail_s3_url_map.electron_player.split(".com")[1];
    const url = useImgixUrl ? import.meta.env.VITE_IMGIX_PREFIX + s3Path + import.meta.env.VITE_IMGIX_SUFFIX : import.meta.env.VITE_CLOUDFRONT_PREFIX + s3Path ;
    setThumbnailUrl(url);
  }

  const setVideoUrlForPlayer = (video) => {
    if(video?.video_s3_url){
      const s3Path = video.video_s3_url.split(".com")[1];
      const url = useImageKitUrl ? import.meta.env.VITE_IMAGEKIT_PREFIX + s3Path + import.meta.env.VITE_IMAGEKIT_SUFFIX + '&' : import.meta.env.VITE_CLOUDFRONT_PREFIX + s3Path + '?';
      setPlayVideoUrl(url + "tag=" + video?.tags);
    }else{
      setPlayVideoUrl()
    }
  }
  
  const onImageLoad = () => {
    setImageLoading(false)
  }

  return (
    <>
      <div className={clsx(styles.videoPlayerMain, openShare && 'shareBackdrop' )}>
        <div className={styles.videoPlayerTitleMain}>
          <div className={styles.videoPlayerTitle}>BRYZOS = TRUST</div>
          <p>Here is your all-access pass to get to know the Bryzos team. Check out this behind-the-scenes view into the discussions and decisions made to optimize this product designed with you specifically in mind.  Let us show you why this app will forever change the way you trade steel.</p>
        </div>
        {
          (thumbnailUrl && !videoNoLongerAvailable) &&
          <div className={styles.videoContainer}>
            {playVideoUrl ? 
              <VideoPlayer
                url={playVideoUrl}
                width={"100%"}
                height={"300px"}
                autoPlay={true}
                playNextVideo={playNextVideo}
                disableNextVideoBtn={disableNextVideoBtn}
                captionUrl={captionUrl}
              /> 
            : (
              <div className={styles.thumbnailContainer_}>
                <DotPulseLoader showLoader={imageLoading}/>
                <img src={thumbnailUrl} height={"300px"} width={"100%"} alt={targetedVideoToPlay?.caption} onLoad={onImageLoad}></img>
                </div>
            )
            }
            {
              (videoMapping && targetedTag && targetedIndex>=0) &&
              <>
                <div className={styles.targetedTag}>
                  <p>{videoMapping[targetedTag][targetedIndex]?.title}</p>
                </div>
                  {videoMapping[targetedTag]?.[targetedIndex]?.description && <div className={styles.descriptionVideo}>
                  <span>
                        {isExpanded || !isLongText ? videoMapping[targetedTag]?.[targetedIndex]?.description : truncatedText}
                  </span>
                    {isLongText && (
                      <button onClick={handleToggle}>
                        {isExpanded ? 'Show Less' : 'Show More'}
                      </button>
                    )}
                  </div>
                  }

                {playVideoUrl && 
                <div className={styles.ViewSection}>
                  {(videoMapping[targetedTag][targetedIndex]?.view_count > 0) && (
                    <div className={styles.rowAlignTitle}><ViewsIcon /> {formatCurrencyWithComma(`${videoMapping[targetedTag][targetedIndex]?.view_count}`)} views</div>
                  ) }
                  {videoMapping[targetedTag]?.[targetedIndex]?.share_video_url && <span className={styles.shareVideoIcon} onClick={openShareVideoPopup}><ShareVideo/> Share</span>}
                </div>
                
                }
              </>
            }
            </div>
        }
        {
          videoNoLongerAvailable && <div className={styles.videoNoLongerAvailable}>
            <span>Video no longer available.</span>
          </div>
        }
        <div className={styles.thumbnailMain}>
        <div className={clsx(styles.episodesThumbnailSection, (thumbnailUrl || playVideoUrl) && styles.thumbnailMinHeight)}>
          {videoLibraryTags.map((tag) => {
            const tagParam = tag.query_param;
            if(videoMapping[tagParam]?.length > 0 && tag.show_on_app)
            return <div key={tag.query_param}>
              <div className={styles.episodesTitle}><span>{tag.display_title}</span><span className={styles.tagSubtitle}>{tag.display_subtitle}</span></div>
              <div className={styles.videoPlayerThumbFlex}>
                {videoMapping[tagParam].map((videoSection: any, index: number) => {
                  const s3Path = videoSection.thumbnail_s3_url_map?.thumbnail_app?.split(".com")[1];
                  const imgUrl = useImgixUrl ? import.meta.env.VITE_IMGIX_PREFIX + s3Path + import.meta.env.VITE_IMGIX_SUFFIX : import.meta.env.VITE_CLOUDFRONT_PREFIX + s3Path;
                  return (
                    <div key={videoSection.id}>
                      {
                        <div className={styles.videoThumb}>
                          <div className={clsx(styles.videoThumbBox, (targetedIndex === index && targetedTag === tag.query_param) && styles.selectedVideo)} >
                            <div className={styles.videoThumbBoxInnerContent}>
                              <img src={imgUrl} alt={videoSection.caption}></img>
                              <div className={styles.overlay} onClick={() => playVideo(tag.query_param, videoSection, index)}>
                                {videoSection?.video_s3_url && <>
                                  {(targetedIndex === index && targetedTag === tag.query_param) && <div className={styles.nowPlatingtxt}>Now Playing</div>}
                                  {!(targetedIndex === index && targetedTag === tag.query_param) &&  <span className={styles.VideoPlayIcon}><VideoPlayIcon /></span>}
                                </>}
                              </div>
                            </div>
                          </div>
                          <p>{videoSection.title}</p>
                          {(videoSection.video_s3_url && videoSection.view_count > 0)&& (
                            <div className={styles.rowAlign}><ViewsIcon /> {formatCurrencyWithComma(`${videoSection.view_count}`)} views</div>
                          ) }
                        </div>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
          })}
        </div>
        </div>
        <div className={styles.footerSection}>
          <div>
              <button onClick={goBack} className={styles.backBtn}>
                  <span>Back</span>
              </button>
          </div>
          <div className={styles.termsAndPatent}>
              <button className={styles.TermsandConditions} onClick={() => navigate(routes.TnCPage, { state: { isViewMode: true, navigateTo: routes.videoLibrary } })}>Terms and Conditions</button>
              <div className={styles.patentPendingText}>Patent Pending</div>
          </div>
        </div>
      </div>
      <ShareVideoUrl openShare={openShare} shareVideoPopupClose={shareVideoPopupClose} videoData={targetedVideoToPlay} />
    </>
  );
};

export default VideoLibrary;